
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Filler);



function LineChart({height, labels, values}) {
    const data = {
        labels: labels,
        datasets: [
            {
                label: "Current",
                data: values,
                pointRadius: 0,
                pointHoverRadius: 2,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 120);
                    gradient.addColorStop(0, "rgba(69,93,255,0.5)");
                    gradient.addColorStop(1, "rgba(69,93,255,0)");
                    return gradient;
                }, //background gradient color
                borderColor: "#455DFF", //line color
                lineTension: 0.6,
                fill: true, // this line allow us to use background gradient

            }
        ]
    };


    return (
        <div style={{ height: height, width: '100%', paddingBottom: 15 }}>
            <Line id="myChart" data={data} options={{
                maintainAspectRatio: false,
                responsive: true,
                showTooltips: true,
                multiTooltipTemplate: "<%= value %>",
                scales: {
                    y: {
                        ticks: {
                            color: "white",
                            stepSize: 1},
                        grid: {
                            display:false
                        },
                        min: 0,
                    },
                    x: {
                        grid: {
                            display:false
                        },
                        ticks: {
                            color: "white"},
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        position: "top",
                        labels:{usePointStyle: true, color: "#aaa", padding: 15, font:{size: 13, family: "Inter", weight: 500}}
                    }
                },
            }} /></div>
    );
}




export { LineChart };
