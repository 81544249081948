import * as React from "react";
import Router from "./Router";
import UserProvider from "./providers/UserProvider";
import { ConfigProvider, theme } from 'antd';
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
    return (
        <ConfigProvider
            theme={{
                // 1. Use dark algorithm
                algorithm: theme.darkAlgorithm,

                // 2. Combine dark algorithm and compact algorithm
                // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
            }}
        >
            <Auth0Provider
                domain={window._env_.AUTH0_DOMAIN}
                clientId= {window._env_.AUTH0_CLIENT_ID}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: window._env_.AUTH0_AUDIENCE,
                }}
            >
                <UserProvider>
                        <Router />
                </UserProvider>
            </Auth0Provider>
        </ConfigProvider>
    );
}


export default App;
