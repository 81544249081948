import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import {Dashboard} from "./components/dashboard";
import {UserContext} from "./providers/UserProvider";
import Offline from "./components/Offline";
import {useContext} from "react";
import ProviderRedirect from "./components/onboarding/ProviderRedirect";
import SetupGuide from "./components/onboarding/SetupGuide";

export default function Router() {
    const {online, permission} = useContext(UserContext);

    if (!online)
        return (
            <Offline/>
        )
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/dashboard/*" element={<Dashboard/>}/>
                <Route path="/git/provider/:provider/redirect" element={<ProviderRedirect/>}/>
                <Route path="/onboarding" element={<SetupGuide/>}/>
                <Route path="/*"
                       element={(permission && ["B001", "B008"].includes(permission)) ?
                           <Navigate to="/dashboard"/> : <Navigate to="/onboarding"/>}/>
            </Routes>
        </BrowserRouter>
    );
}