import {useContext, useEffect, useState} from "react";
import {Button, Space, Table} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {PageCard} from "../dashboard/elements/BaseCard";
import * as React from "react";
import UserModal from "../dashboard/elements/UserModal";
import {UserContext} from "../../providers/UserProvider";

export default function UserOverview() {
    const {organisationUsers, reloadOrganisationUsers } = useContext(UserContext);
    const [modal, setModal] = useState(false);
    const [initialUser, setInitialUser] = useState();

    const edit = (record) => {
        setModal(true)
        setInitialUser(record)
    }

    useEffect(()=>{
        reloadOrganisationUsers()
        // eslint-disable-next-line
    }, [reloadOrganisationUsers])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Role',
            dataIndex: 'role'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        },
        {
            title: '',
            dataIndex: 'buttond',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />}
                            onClick={() => edit(record)}>Edit</Button>
                </Space>
            ),
        },
    ];

    const data = organisationUsers?.map(user => {
        return {
            name: user.userInfo.name,
            email: user.userInfo.email,
            role: user.role,
            status: user.status,
            id: user._id
        }
    })
    return <PageCard  menuFunction={()=> {
        setModal(true)
        setInitialUser(undefined)
    }}>
        <UserModal open={modal} onClose={()=>setModal(false)} initialUser={initialUser} />
        <h2>Users waiting for acceptance</h2>
        <p className="secondary">When users try to login using SSO, you can accept their access here.</p>
        <Table locale={{emptyText: 'No users waiting for acceptance.'}} columns={columns} dataSource={data}></Table>
    </PageCard>
}