import {useState} from "react";
import {Button, Form, Input, Layout, Checkbox, Timeline, Steps, Space, Spin, Alert} from "antd";
import {useSearchParams} from "react-router-dom";

const {Content} = Layout;

export default function AzureDevSetup({apiKey, finish}) {
    const [step, setStep] = useState(0);
    const [waiting, setWaiting] = useState(false);
    const [name, setName] = useState("");
    const [manualPermissionCreation, setManualPermissionCreation] = useState(false);
    const [searchParams] = useSearchParams()

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    async function install() {
        setWaiting(true)
        await finish({orgName: name, manualPermissionCreation: manualPermissionCreation});
        await sleep(2000)
        setWaiting(false)
    }

    async function moveToStep3(){
        setStep(3)
        await install()
    }

    function save(values) {
        setName(values.orgName);
        setStep(1);
    }

    return <>
        <Layout style={{width: "100%", padding: 80}}>
            <Content style={{
                minHeight: "100vh",
                width: 900,
                margin: "0 auto"
            }}>
                <h1>Azure Devops Setup</h1>
                <Steps
                    style={{marginTop: 30}}
                    current={step}
                    items={[
                        {
                            title: 'Organisation name'
                        },
                        {
                            title: 'Add App basic permissions'
                        },
                        {
                            title: 'Setup service hooks',
                        },
                        {
                            title: 'Finish Installation'
                        },
                    ]}
                />
                {step === 0 && <>
                    <h3>Provide organisation name</h3>
                    <Form
                        name="azure-setup"
                        layout="vertical"
                        onFinish={save}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        style={{maxWidth: 800, marginTop: 20}}
                        autoComplete="off"
                        initialValues={{
                            orgName: ""
                        }}
                    >
                        <Form.Item
                            label="Please provide us the organisation name of your azure devops account."
                            name="orgName"
                            rules={[{required: true, message: 'Please double check your organisation name'}]}>
                            <Input placeholder="FirstMate" style={{width: 300}}/>
                        </Form.Item>
                    </Form>
                    <Button type="primary" form="azure-setup" key="submit" htmlType="submit">
                        Next Step
                    </Button>
                </>}
                {step === 1 && <>
                    <h3>Step 1 : Add App basic permissions </h3>
                    <Timeline
                        style={{marginTop: 30}}
                        items={[
                            {
                                children: <>Navigate to <a href="https://dev.azure.com/">azure devops</a></>,
                            },
                            {
                                children: <>Click on organization settings on the bottom left of the page.</>,
                            },
                            {
                                children: <> Navigate to the users tab and add the FirstMateBot service principal to the
                                    list.<br/>
                                    Make sure to provide basic access to all relevant projects with contributor
                                    rights.</>
                            },
                        ]}
                    />

                    <Space direction={"vertical"}>
                        <img alt="user-onboarding" src="/azureDevAddUserGuide.png"
                             style={{width: 900, borderRadius: 10}}/>
                        <Button type={"primary"} onClick={() => setStep(2)}>Next</Button></Space>
                </>}
                {step === 2 && <>
                    <h3>Step 2: Setup service hooks.</h3>
                    <Checkbox checked={manualPermissionCreation}
                              onChange={() => setManualPermissionCreation(!manualPermissionCreation)}>Manually setup
                        permissions
                        (not recommended)</Checkbox>
                    {manualPermissionCreation ? (
                        <>
                            <Timeline
                                style={{marginTop: 30}}
                                items={[
                                    {
                                        children: <>For each relevant project in your organization, click on the
                                            project.</>,
                                    },
                                    {
                                        children: <>Open on the bottom left the project settings & open the "Service
                                            hooks"
                                            tab.</>,
                                    },
                                    {
                                        children: <>Click on Create subscription or the "+" sign and add a service hook of type "Web Hooks". And click "next".</>
                                    },
                                    {
                                        children: <>On the top you can select the trigger type. Create a hook for all of
                                            the
                                            following trigger types. And click "next".
                                            <ul>
                                                <li>Repository created</li>
                                                <li>Repository deleted</li>
                                                <li>Pull request commented on</li>
                                                <li>Pull request created</li>
                                                <li>Pull request merge attempted</li>
                                                <li>Pull request updated</li>
                                                <li>Run job state changed</li>
                                                <li>Work item created</li>
                                                <li>Work item commented on</li>
                                                <li>Work item deleted</li>
                                                <li>Work item updated</li>
                                                <li>Code pushed</li>
                                            </ul></>
                                    },
                                    {
                                        children: <>Define the actions of the webhook. Perform a "Post with HTTP" action
                                            with
                                            following details. And click "Finish".
                                            <ul>
                                                <li>Url: <pre>https://api.eu.firstmate.cloud/api/v1/git/provider/azuredev/webhook</pre>
                                                </li>
                                                <li>http
                                                    headers: <pre>authorization: {apiKey}<br/>provider-id: {searchParams.get("tenant")}@{name}</pre>
                                                </li>
                                            </ul></>
                                    },
                                    {
                                        children: <>Repeat process for all of the above defined trigger types and relevant projects.</>
                                    }
                                ]}
                            />
                            <Space direction={"vertical"}>
                                <img alt="user-onboarding" src="/azureDevAddServiceHook.png"
                                     style={{width: 900, borderRadius: 10}}/>
                                <Button type={"primary"} onClick={moveToStep3}>Next</Button></Space>

                        </>
                    ) : (
                        <>
                            <Timeline
                                style={{marginTop: 30}}
                                items={[
                                    {
                                        children: <>To automatically create service webhooks on each project, the FirstMateBot will temporary require "Project Administrator" access rights.<br/> For more
                                            granular permissions we are waiting on this <a
                                                href={"https://developercommunity.visualstudio.com/t/make-service-hook-permissions-more-flexible/365713"}> azure
                                                issue.</a></>,
                                    },
                                    {
                                        children: <>Click again on organization settings on the bottom left of the page.</>,
                                    },
                                    {
                                        children: <> Navigate to the users tab and click for more options on the FirstMateBot.</>
                                    },
                                    {
                                        children: <>Click on manage access. And temporary grant the "Project Administrator" access to all relevant projects.
                                        </>,
                                    },
                                    {
                                        children: <> After successful installation, we recommend to revoke the "Project Administrators" access from FirstMateBot</>
                                    },
                                ]}
                            />
                            <Space direction={"vertical"}>
                                <Button type={"primary"} onClick={moveToStep3}>Next</Button></Space>
                        </>
                    )}

                </>}
                {step === 3 && <>
                    <h3>Check if installation was successfull</h3>
                    <div>
                        <>Sometimes it can take up to 10 minutes to makes sure all necessary permissions are available
                            to us.
                        </>
                        <Spin spinning={waiting} delay={0}>
                            <Alert
                                style={{marginTop: 30}}
                                type="info"
                                message="We do not have the necessary permissions at this moment."
                                description='Please try again by clicking "Check installation status"'
                            />
                        </Spin>
                        <Spin spinning={waiting} delay={0}>
                            <Alert
                                style={{marginTop: 30}}
                                type="warning"
                                message='Do not forget to revoke the "Project Administrator" rights from the FirstMateBot service principal.'
                                description='Change the "Project Administrator" rights back to "Project Contributor" after a successful installation.'
                            />
                        </Spin>
                    </div>
                    {!waiting && <Button type="primary" onClick={install} style={{marginTop: 40}}>
                        Check installation status
                    </Button>}
                </>}
            </Content>
        </Layout>

    </>

}
