import {Button, Card, Col, Tag, Row, Space, Form, Input} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../providers/UserProvider";
import {FallOutlined} from "@ant-design/icons";
import AzureDevModal from "../settings/modals/AzureDevModal";
import {squircle} from 'ldrs'
import {useAuth0} from "@auth0/auth0-react";
import Confetti from "react-confetti";
import {useNavigate, useSearchParams} from "react-router-dom";

squircle.register()

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

function GitSelection() {
    const [azuredevModal, setAzuredevModal] = useState(false);

    function logGA(platform) {
        window.gtag('event', 'start_onboarding', {
            event_category: 'Onboarding',
            event_label: 'Start Onboarding',
            platform: platform,
        });
    }

    function azureDevStart() {
        logGA("azure");
        setAzuredevModal(true);
    }

    return (
        <Row gutter={gutter} style={{marginTop: 20}}>
            <Col span={8}>
                <Button className="git-install" onClick={() => logGA("github")} href={window._env_.GITHUB_REDIRECT_URL}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="github" src="/icons/github.png" width={100}/>
                        </Col>
                    </Row>

                </Button>
            </Col>
            <Col span={8}>
                <Button className="git-install"
                        onClick={() => logGA("bitbucket")}
                        href={window._env_.BITBUCKET_REDIRECT_URL}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="bitbucket" src="/icons/bitbucket.png" width={160}/>
                        </Col>
                    </Row>

                </Button>
            </Col>
            <Col span={8}>
                <Button className="git-install" onClick={azureDevStart}>
                    <Row
                        type="flex"
                        justify="center"
                        align="middle"
                        style={{minHeight: '100%'}}
                    >
                        <Col>
                            <img alt="azuredev" src="/icons/azure-devops.png" width={130}/>
                        </Col>
                    </Row>
                </Button>
                <AzureDevModal open={azuredevModal} onClose={() => setAzuredevModal(false)}/>
            </Col>
        </Row>

    )
}

function CreateUser() {
    const {initOrganisation} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const {user} = useAuth0();

    async function create(values) {
        setLoading(true);
        const response = await initOrganisation({...values, email: user.email});
        console.log(response);
        setLoading(false);
    }

    return <Card className={"onboarding-card"}>
        <div className="modal-background"></div>
        <Space style={{position: "absolute", top: 85, right: 30}}></Space>
        <Card style={{textAlign: "left"}}>
            <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                <FallOutlined/></div>
            <h3 style={{width: 300, marginTop: 10}}>Create account</h3>
        </Card>
        <h3 style={{textAlign: "left"}}>Tell us who you are:</h3>
        <Form
            name="org-setup"
            layout="vertical"
            onFinish={create}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 800, marginTop: 20}}
            autoComplete="off"
            initialValues={{
                // eslint-disable-next-line
                ["name"]: user.nickname

            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{required: true, message: 'Please check you filled in your name'}]}>
                <Input placeholder="John Doe" style={{width: 300}}/>
            </Form.Item>
            <Form.Item
                label="Organisation"
                name="org"
                rules={[{required: true, message: 'Please provide an organisation name'}]}>
                <Input placeholder="your-org-name" style={{width: 300}}/>
            </Form.Item>
            <Button type="primary" form="org-setup" key="submit" htmlType="submit" loading={loading}>
                Next Step
            </Button>

        </Form>

    </Card>

}

function LinkGit({status}) {
    const {organisation, checkRepos} = useContext(UserContext);
    const [scanReady, setScanReady] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    const getStatus = () => {
        if (status === "error")
            return <Tag color="red" style={{position: "absolute", right: 20, top: 30}}>Error</Tag>
        if (!searchParams.get("scan"))
            return <Tag color="orange" style={{position: "absolute", right: 20, top: 30}}>In Progress</Tag>

        if (!scanReady)
            return <l-squircle
                style={{position: "absolute", right: 30, top: 23}}
                size={30}
                stroke="4"
                stroke-length="0.25"
                bg-opacity="0.2"
                speed="2"
                color="white"
            ></l-squircle>
        else return <Tag color="green" style={{position: "absolute", right: 20, top: 30}}>Done</Tag>

    }
    useEffect(() => {
        if (organisation?.repos?.length > 0) {
            setScanReady(true);
        }
    }, [organisation])


    useEffect(() => {
        const intervalID = setInterval(() => {
            if (searchParams.get("scan")) {
                checkRepos()
            }
        }, 2000);

        return () => clearInterval(intervalID);
    }, [checkRepos, organisation, searchParams]);

    const buttonClick = () => {

        localStorage.setItem('firstmate-' + organisation._id, true);
        navigate("/dashboard")
    }


    return <Card className={"onboarding-card"}>
        {scanReady && <Confetti
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0}}
            colors={["#FF99FF", "#AABBFF", "#FFD700"]}
            numberOfPieces={300}
            gravity={0.15}
            recycle={false}
        />}
        <div className="modal-background"></div>
        <Space style={{position: "absolute", top: 85, right: 30}}></Space>
        <Card style={{textAlign: "left"}}>
            <div className="modal-icon" style={{marginBottom: 0, float: "left", marginRight: 20}}>
                <FallOutlined/></div>
            <h3 style={{width: 300, marginTop: 10}}>Link your Git provider</h3>
            {getStatus()}


        </Card>
        {!searchParams.get("scan") && <><h3 style={{textAlign: "left"}}>Choose your source
            control:</h3><GitSelection/></>}
        <Button type={"primary"} style={{visibility: scanReady ? "visible" : "hidden"}} onClick={buttonClick}>Start
            Using
            FirstMate</Button>
    </Card>

}

export default function SetupGuide({status}) {
    const {organisation, logOut} = useContext(UserContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!organisation)
            return
        if (organisation.repos?.length > 0 && localStorage.getItem('firstmate-' + organisation._id)) {
            navigate("/dashboard");
        }
    }, [navigate, organisation]);


    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#161B26"
        }}>
            <p style={{position: "absolute", top: 10, right: 10, cursor: "pointer"}} onClick={logOut}>Log Out</p>
            <img alt="logo" src={"/logo.png"} style={{position: "absolute", width: 200, top: 10, left: 10}}/>
            <div style={{textAlign: "center"}}>
                <h1>Setup your account</h1>
                <p style={{marginTop: 10}}>You're almost ready to activate faster & better pull requests.
                    It only takes 2 clicks.</p>
                {organisation ? <LinkGit status={status}/> :
                    <CreateUser/>}
            </div>
            <Button style={{position: "absolute", bottom: 15, left: 10}} target="_blank" rel="noopener noreferrer"
                    href={"https://join.slack.com/t/firstmatecommunity/shared_invite/zt-2u9f0yw5e-Av1~asi1XbJQvZ2d4mhyMg"}>Need
                support? Join our Slack community</Button>
        </div>
    );
}