import {Chart as ChartJS, registerables} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(...registerables);


function BarChart({labels, data, height, hideLegend}) {
    const dataset = {
        labels: labels,
        datasets: Object.keys(data).map(type =>
            ({
                label: type,
                data: data[type].values,
                pointRadius: 0,
                backgroundColor: data[type].color,
                pointHoverRadius: 2,
                barThickness: 40,
                borderRadius: 10,
                lineTension: 0.6,
                fill: true, // this line allow us to use background gradient

            }))
    };

    return (
        <div style={{height: height, width: '100%', paddingBottom: 15}}>
            <Bar id="barChart" data={dataset} options={{
                maintainAspectRatio: false,
                responsive: true,
                showTooltips: true,
                multiTooltipTemplate: "<%= value %>",

                scales: {
                    y: {
                        ticks: {
                            stepSize: 1,
                            color: "white"
                        },
                        grid: {
                            display: false
                        },
                        stacked: true,
                    },
                    x: {
                        grid: {
                            display: false
                        },
                        stacked: true,
                        ticks: {
                            color: "white"
                        },
                    }
                },
                plugins: {
                    legend: {
                        display: !hideLegend,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            color: "#aaa",
                            padding: 15,
                            font: {size: 13, family: "Inter", weight: 500}
                        }
                    }
                },
            }}/></div>
    );


}

export {BarChart};
