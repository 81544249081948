import {createContext, useEffect, useState, useCallback, useContext} from 'react';
import {UserContext} from "./UserProvider";

export const StatisticsContex = createContext([]);


export default function StatisticsProvider({children}) {
    const [stats, setStats] = useState([])
    const {backendService} = useContext(UserContext);


    const fetchData = useCallback(async () => {
        const stats = await backendService.getPRStats()
        setStats(stats);
    }, [backendService]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <StatisticsContex.Provider
            value={{stats}}>
            {children}
        </StatisticsContex.Provider>
    )
}
