import {Alert, Space, Steps} from "antd";
import {UserContext} from "../../../providers/UserProvider";
import {useContext} from "react";
import * as React from "react";


function StepStatus() {
    const {organisation} = useContext(UserContext);
    let step = 1;
    if (organisation?.accessRights.gitProviders.length > 0)
        step = 2
    if (organisation?.subscription.users.length > 1)
        return <></>
    return <Steps
        style={{marginBottom: 30}}
        size="small"
        current={step}
        items={[
            {
                title: 'Setup Account',
            },
            {
                title: 'Connect Repositories',
            },
            {
                title: 'Open a Pull Request ',
            },
        ]}
    />
}

function Alerts({hideButtons}) {
    const {organisation} = useContext(UserContext);
    if (!organisation?.repos || organisation?.repos.length === 0)
        return <Alert message="No Linked Repositories" type="warning" style={{marginBottom: 20, marginTop: 20}}
                      description={<><p>Install FirstMate on Github, AzureDevOps or Bitbucket to continue.</p>
                          {!hideButtons && <a className="white_link" href="/dashboard/repos">Link your
                              repositories</a>}</>} showIcon/>

}

export default function DashBoardHeader({title, hideAlerts, hideButtons, buttons}) {


    return (
        <div>
            <StepStatus/>
            <h1>{title}</h1>
            {!hideAlerts && <Alerts hideButtons={hideButtons}/>}
            <div style={{float: "right"}}>
                <Space size={[10, 0]} wrap>
                    {buttons}
                </Space>
            </div>
        </div>
    );

}
