import * as React from "react";
import StatisticsProvider from "../../../providers/StatisticsProvider";
import DashBoardHeader from "../elements/Header";


export default function Rules() {

    return (
        <StatisticsProvider>
            <div>
                <DashBoardHeader title={"Guardrails"} hideAlerts={true}/>
            </div>
        </StatisticsProvider>

    );


}
