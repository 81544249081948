import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "../../providers/UserProvider";
import AzureDevSetup from "./AzureSetupSteps"
import BlockingModal from "../dashboard/elements/PermissionModal";
import SetupGuide from "./SetupGuide";


export default function ProviderRedirect() {
    const navigate = useNavigate();
    const {backendService, iam, organisation, askReload} = useContext(UserContext)

    const {provider} = useParams();
    const [loading, setLoading] = useState(false);
    const [gitStatus, setGitStatus] = useState("loading");
    const [searchParams] = useSearchParams()

    const checkAccessRights = async values => {
        backendService.addAccessRights("provider", {
            id: `${provider}-app`,
            resourceName: `${provider}-app`,
            provider: provider,
            resourceDetails: {
                ...paramsToObject(searchParams),
                ...values,
                manualPermissionCreation: values.manualPermissionCreation
            }
        }).then(() => {
            askReload(true);
            setGitStatus("linked");
            //navigate("/dashboard/repos");
            //setLoading(true)
        }).catch((e) => console.log(e))

    }


    const createAccessRight = useCallback(async (values) => {
        setLoading(true);
        await backendService.addAccessRights("provider", {
            id: `${provider}-app`,
            resourceName: `${provider}-app`,
            provider: provider,
            resourceDetails: {
                ...paramsToObject(searchParams),
                ...values,
            }
        }).then(() => {
            askReload(true);
            setGitStatus("linked")
            navigate("/onboarding?scan=true");

            //setLoading(true)
        }).catch((e) => {
            console.log(e);
            setGitStatus("error");
           // navigate("/onboarding");
        })
        //navigate("/dashboard/repos");
        //setLoading(true)

    }, [searchParams, provider, backendService, navigate, askReload])

    useEffect(() => {
        if (!iam || loading)
            return
        if (provider !== "azuredev") {
            createAccessRight(undefined).then()
        }
    }, [provider, iam, loading, createAccessRight])
    const params = paramsToObject(searchParams)
    if (params.error) {
        return <BlockingModal title={"Error: " + params.error} content={params.error_description}></BlockingModal>
    }

    if (provider === "azuredev") {
        return loading ?
            <SetupGuide status={gitStatus}/> : <AzureDevSetup apiKey={organisation?.apiKey} finish={checkAccessRights}/>
    }
    return <SetupGuide status={gitStatus}/>
}

function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}