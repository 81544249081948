import * as React from "react";
import DashBoardHeader from "../dashboard/elements/Header";
import {Col, Radio, Row} from "antd";
import {useState} from "react";
import AccessRightsProvider from "../../providers/AccessRightsProvider";
import AccessRightsOverview from "./AccessRights";
import UserOverview from "./UserOverview";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}



export default function Settings() {
    const [view, setView] = useState("access");


    return <div>
        <DashBoardHeader title={"Settings"} hideAlerts={true} />
        <Radio.Group value={view} onChange={(change) => setView(change.target.value)}>
            <Radio.Button value="access">Access Overview</Radio.Button>
            <Radio.Button value="users">Users</Radio.Button>
        </Radio.Group>
        <Row gutter={gutter}>
            <Col span={24}>
                {view === "access" &&
                    <AccessRightsProvider><AccessRightsOverview/></AccessRightsProvider>}
                {view === "users" && <UserOverview/>}

            </Col>
        </Row>
    </div>
}