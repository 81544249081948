import {useContext} from "react";
import * as React from "react";
import {LineChart} from "../../chart/charts/LineChart";
import StatisticsProvider, {StatisticsContex} from "../../../providers/StatisticsProvider";
import {Col, Row} from "antd";
import {BarChart} from "../../chart/charts/BarChart";
import DashBoardHeader from "../elements/Header";
import {UserContext} from "../../../providers/UserProvider";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}

function PRLines() {
    const {stats} = useContext(StatisticsContex);

    return (<>
        <h1 style={{marginBottom: 20}}>Processed Pull requests</h1>
        <LineChart labels={stats.map(s => s.date.slice(5))} values={stats.map(s => s.count)}/>
    </>)
}

function Issues() {
    const {stats} = useContext(StatisticsContex);
    const values = {
        security: {values: stats.map(s => s.security), color: '#780116'},
        logging: {values: stats.map(s => s.logging), color: '#c32f27'},
        testing: {values: stats.map(s => s.testing), color: '#d8572a'},
        documentation: {values: stats.map(s => s.documentation), color: '#db7c26'},
        readability: {values: stats.map(s => s.readability), color: '#ff9505'},
        other: {values: stats.map(s => s.other), color: '#f7b538'},
    }

    return (<>
        <h1 style={{marginBottom: 20}}>Detected issues</h1>
        <BarChart height={250} labels={stats.map(s => s.date.slice(5))} data={values}/>
    </>)
}

export default function Report() {
    const {organisation} = useContext(UserContext);

    return (
        <StatisticsProvider>
            <div>
                <DashBoardHeader title={"Overview Dashboard"} hideAlerts={true}/>
                {organisation?.subscription.users.length === 0 ?
                    <div className={"loading-icon"}>
                        <img alt="hourglass" src="/sand.png" style={{width: 60}}/>
                        <h3>Nothing to see here yet.</h3>
                        <p>Create a Pull Request first.</p>
                    </div>:

                    <Col>
                        <Row gutter={gutter} style={{marginTop: 30, marginLeft: 0}}>
                            <PRLines/>
                        </Row>
                        <Row gutter={gutter} style={{marginTop: 30, marginLeft: 0}}>
                            <Issues/>
                        </Row>
                    </Col>}
            </div>
        </StatisticsProvider>

    );


}
