import {createContext, useCallback, useEffect, useState} from 'react';
import BackendService from "../services/BackendService";
import {useAuth0} from "@auth0/auth0-react";
import api from "../services/api";
import BlockingModal from "../components/dashboard/elements/PermissionModal";
import * as React from "react";
import Loader from "../components/dashboard/elements/Loader";

export const UserContext = createContext([]);


export default function UserProvider({children}) {
    const {isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently, isLoading} = useAuth0();

    const [online, setOnline] = useState(true);
    const [isLoggingOut, setLoggingOut] = useState(false);
    const [organisationUsers, setOrganisationUsers] = useState([]);
    const [iam, setIam] = useState()
    const [permission, setPermission] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Something went wrong in getting your user rights. Please contact us to resolve this issue.")
    const [organisation, setOrganisation] = useState();
    const [reloadNecessary, askReload] = useState(true)
    const [userLoading, setLoading] = useState(true)
    const backendService = new BackendService()


    const getMe = async () => {
        try {
            const fetchedIam = await backendService?.getMe();
            setPermission(true);
            setIam(fetchedIam)
            setOrganisation(fetchedIam.organisation);
            setLoading(false);
            return fetchedIam.organisation;
        } catch (e) {
            setLoading(false);
            if (["B001", "B008"].includes(e.response?.data?.code)) {
                if (sessionStorage.getItem("invitationToken")) {
                    const invitationToken = sessionStorage.getItem("invitationToken");
                    sessionStorage.removeItem("invitationToken");
                    await backendService.acceptInvite({invitationToken})
                    window.location.reload()
                    return;
                } else {
                    setErrorMessage(e.response?.data?.message);
                    setPermission(e.response?.data?.code);
                    return;
                }
            }
            if (e.response?.data?.code === "B002") {
                setPermission("B002");
                //Todo Add I18N package and map error code to messages.
                setErrorMessage("You don't have access to see the data linked to your organisation. Ask someone with access to FirstMate to accept you.");
                return;
            }
            setOnline(false);

        }

    }

    const initOrganisation = async (values) => {
        const org = await backendService.initOrganisation({
            name: values.org,
            user: {
                name: values.name,
                email: values.email
            }
        });
        setOrganisation(org);
        await getMe();
    }
    useEffect(() => {
        if (isAuthenticated) {
            sessionStorage.removeItem('isLoggingOut');
            setLoggingOut(false);
        }
    }, [isAuthenticated]);

    const repoRefresh = async () => {
        await backendService.repoRefresh();
        await getMe();
    }
    const getOnboardingStatus = async () => {
        return await backendService.getOnboardingStatus();
    }

    const logOut = () => {
        sessionStorage.setItem('isLoggingOut', 'true');
        logout({logoutParams: {returnTo: window.location.origin}});
    }
    useEffect(() => {
        const loggingOutStatus = sessionStorage.getItem('isLoggingOut');
        if (loggingOutStatus === 'true') {
            setLoggingOut(true);
        }
    }, []);

    const inviteUser = async (values) => {
        await backendService.inviteUser({
            role: values.role,
            userInfo: {
                name: values.name,
                email: values.email
            }
        });
        await reloadOrganisationUsers()
    }

    const checkRepos = async () => {
        const fetchedIam = await backendService?.getMe();
        if (fetchedIam.organisation.repos.length > 0)
            askReload(true);

    }

    const reloadOrganisationUsers = useCallback(async () => {
        const users = await backendService.getUsers()
        setOrganisationUsers(users);
        // eslint-disable-next-line
    }, [setOrganisationUsers])


    useEffect(() => {
        if (window.location.pathname === "/user/invite") {
            const token = window.location.search.split("=")[1]
            sessionStorage.setItem("invitationToken", token);
        }
        if (!reloadNecessary)
            return;
        if (!isAuthenticated && !isLoading ) {
            loginWithRedirect();
            return <div className={"center-loading"}>
                <Loader size="80"/>
            </div>
        } else if (isAuthenticated) {
            api.interceptors.request.use(async (config) => {
                try {
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: window._env_.AUTH0_AUDIENCE,
                        }
                    })
                    config.headers = {
                        "Content-Type": "application/json",
                        provider: "auth0",
                        Authorization: `Bearer ${accessToken}`
                    }
                } catch (e) {
                    await loginWithRedirect({
                        audience: window._env_.AUTH0_AUDIENCE,
                    })
                }


                return config
            })
            getMe().then();
            askReload(false);
        }
        // eslint-disable-next-line
    }, [isAuthenticated, isLoading, reloadNecessary]);
    return (
        isLoading || isLoggingOut || userLoading ? (
            <div className={"center-loading"}>
                <Loader size="80"/>
            </div>
        ) : isAuthenticated ? (
            <UserContext.Provider
                value={{
                    iam,
                    organisation,
                    initOrganisation,
                    permission,
                    errorMessage,
                    backendService,
                    logOut,
                    online,
                    askReload,
                    organisationUsers,
                    inviteUser,
                    repoRefresh,
                    reloadOrganisationUsers,
                    isLoggingOut,
                    getOnboardingStatus,
                    checkRepos
                }}>
                {children}
            </UserContext.Provider>
        ) : (
            <BlockingModal title="It seems that you are not logged in, please refresh the page."
                           content={errorMessage}/>
        )
    )
}