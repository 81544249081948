import {Button, Flex, Table, Tag} from 'antd';
import {RedoOutlined, BranchesOutlined} from "@ant-design/icons";
import {useContext, useState} from "react";
import {PageCard} from "./BaseCard";
import {SuggestionContex} from "../../../providers/SuggestionProvider";
import {UserContext} from "../../../providers/UserProvider";

const sleep = ms => new Promise(r => setTimeout(r, ms));

const providerLogo = {
    azuredev: "/icons/azure-devops.jpeg",
    bitbucket: "/icons/bitbucket.png",
    github: "/icons/github.png",
}

function RepoTable({columns}) {
    const {organisation} = useContext(UserContext)
    const {suggestions} = useContext(SuggestionContex)

    organisation?.repos?.forEach(repo => {
        repo.changes = 0;
        suggestions?.forEach(sugg => {
            sugg.prs?.forEach(pr => {
                if (pr.rep_id === repo.id)
                    repo.changes += 1;
            })
        })
    })


    return <Table locale={{
        emptyText: <>No linked repositories. <a href="/dashboard/settings/users">Manage git providers.</a> </>
    }}
                  columns={columns} dataSource={organisation?.repos.filter(repo => !repo.metadata?.guidelines)}
                  ></Table>
}


export function Guidelines({columns}) {
    const {organisation} = useContext(UserContext)
    const gl = organisation?.repos?.filter(a => a.metadata?.guidelines);
    if (gl && gl.length > 0)
        return <>
            <p class={"secondary"}>We use this repo as a reference for your company best practices.</p>
            <Flex justify={"left"} style={{marginTop: 15}}>
                <Button icon={<BranchesOutlined/>} href={gl[0].link} target="_blank">{gl[0].id}</Button></Flex>
        </>

    return <>
        <p className={"secondary"}>If you create a guideline repository, Firstmate will auto-detect it. <a rel="noreferrer"
                                                                                   href="https://docs.firstmate.io/guidelines/repo"
                                                                                   target="_blank">Setup a guideline
            repo</a> to teach FirstMate your company best practices.</p>
        <p style={{marginTop: 5}} className={"secondary"}>When no custom guidelines are used, we use this basic public
            guideline repo:</p>
        <Flex justify={"left"} style={{marginTop: 15}}>
            <Button icon={<BranchesOutlined/>} href="https://github.com/firstmatecloud/general-guidelines-repo"
                    target="_blank">firstmatecloud/general-guidelines-repo</Button></Flex></>

}

export default function RepoList() {
    const {repoRefresh} = useContext(UserContext);
    const [refreshing, setRefreshing] = useState(false)

    const getStatus = (repo) => {
        if (!repo.enabled) return <Tag className="tag-rounded" color="orange">Disabled</Tag>
        return <Tag className="tag-rounded" color="green">{repo.status}</Tag>
    }

    const refreshRepo = async () => {
        setRefreshing(true);
        repoRefresh();
        await sleep(5000);
        window.location.reload();

    }

    const columns = [
        {
            title: '',
            dataIndex: 'img',
            width: 100,
            render: (_, record) => <img alt="avatar" src={record.avatar || providerLogo[record.provider]}
                                        className="avatar"/>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 250,
        },
        {
            title: 'Provider',
            dataIndex: 'provider'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            render: (_, record) => getStatus(record),
            width: 150,
            sorter: (a, b) => a.enabled - b.enabled,
            defaultSortOrder: 'descend'

        }
    ];

    return (
        <>
            <PageCard>
                <h2>Guideline Repo</h2>
                <Guidelines/>
            </PageCard>
            <PageCard>
                <h2>Linked repositories</h2>

                <Button style={{float: "right"}} icon={<RedoOutlined/>} loading={refreshing}
                        onClick={refreshRepo}>{refreshing ? "Refreshing repo's..." : "Refresh list"}</Button>
                <RepoTable columns={columns}/>
            </PageCard>
        </>

    );
}



