import * as React from "react";
import FullScreen from "./dashboard/screens/FullScreen"

export default function Offline() {

    return (
        <FullScreen>
            <p style={{color: "black",margin: "20px 40px"}}>We are currently offline and investigating the problem.</p>
        </FullScreen>
    );
}