import {createContext, useEffect, useState, useCallback, useContext} from 'react';
import {UserContext} from "./UserProvider";

export const AccessRightsContext = createContext([]);


export default function AccessRightsProvider({children}) {
    const [accessRights, setAccessRights] = useState()
    const {backendService} = useContext(UserContext);
    const [focussedAccessRight, setFocussedAccessRight] = useState();
    const [focussedAccessRightParams, setFocussedAccessRightParams] = useState();

    const fetchData = useCallback(async () => {
        const accessRightsResp = await backendService.getAccessRights()
        setAccessRights(accessRightsResp);
        if(focussedAccessRightParams){
            const accessRight = accessRightsResp[focussedAccessRightParams.type]?.find(accessRight => accessRight.id === focussedAccessRightParams.accessRightId)
            setFocussedAccessRight(accessRight)
        }
    }, [backendService, setFocussedAccessRight, setAccessRights, focussedAccessRightParams]);


    const updateFocusAccessRightParams = useCallback(async (type, accessRight) => {
        const focusAccessRightParams = {
            type,
            accessRightId: accessRight?.id
        }
        setFocussedAccessRightParams(focusAccessRightParams)
        setFocussedAccessRight(accessRight)
    },[setFocussedAccessRight, setFocussedAccessRightParams])





    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <AccessRightsContext.Provider
            value={{accessRights,focussedAccessRight, focussedAccessRightParams,  updateFocusAccessRightParams}}>
            {children}
        </AccessRightsContext.Provider>
    )
}