import * as React from "react";
import {Col, Row, Card} from "antd";
import {SecurityScanOutlined, ReadOutlined, EyeOutlined, ExpandAltOutlined, EditOutlined} from "@ant-design/icons"
import DashBoardHeader from "../elements/Header";
import {Guidelines} from "../elements/RepoList";
import {PageCard} from "../elements/BaseCard";

const gutter = {xs: 8, sm: 16, md: 24, lg: 32}


export default function Home() {
    const iconStyle = {fontSize: 25, marginRight: 10, paddingTop: 0, float: "left"}
    const iconLinkStyle = {fontSize: 20, float: "right"}
    return (
        <div>
            <DashBoardHeader title={"Welcome to FirstMate"} hideAlerts={false}/>
            <div className={"section"}>
                <h2>Included out-of-the-box, just open a pull request.</h2>
                <Row gutter={gutter}>
                    <Col span={12}>
                        <Card className="feature-card"><EditOutlined style={iconStyle}/>
                           Automated pull request summaries

                            <span style={{float: 'right'}} className={"secondary"}>
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.firstmate.io/summaries">read more</a>
                        </span>
                        </Card>
                        <Card className="feature-card"><SecurityScanOutlined style={iconStyle}/> Scan your code against
                            known security issues
                            <span style={{float: 'right'}} className={"secondary"}>
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.firstmate.io/tools">read more</a>
                        </span>
                        </Card>
                        <Card className="feature-card"><SecurityScanOutlined style={iconStyle}/> Debug failed pipelines
                            <span style={{float: 'right'}} className={"secondary"}>
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.firstmate.io/how">read more</a>
                        </span>
                        </Card>

                        <Card className="feature-card"><EyeOutlined style={iconStyle}/>
                            Scan code against our Standard Guidelines

                            <span style={{float: 'right'}} className={"secondary"}>
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.firstmate.io/guidelines">read more</a>
                        </span>
                        </Card>
                        <Card className="feature-card"><ReadOutlined style={iconStyle}/>
                            Spot documentation drift.
                            <span style={{float: 'right'}} className={"secondary"}>
                            <a target="_blank" rel="noopener noreferrer" href="https://docs.firstmate.io/review">read more</a>
                        </span>
                        </Card>

                    </Col>
                    <Col span={12} style={{textAlign: "center"}}>
                        <img alt="guidelines" style={{height: 350}} src={"./guidelines.png"}/>

                    </Col>
                </Row>
            </div>
            <div className={"section"}>
                <h2>Optional customisation</h2>
                <PageCard>
                    <h3>Create a Guideline repository.</h3>
                    <Guidelines/></PageCard>
            </div>
            <div className={"section"}>
                <h2>Useful Links</h2>
                <Row gutter={gutter}>
                    <Col span={12}>
                        <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/vincent-pjfv/30mino">
                            <Card className="link-card">Book a support call<ExpandAltOutlined
                                style={iconLinkStyle}/></Card>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://join.slack.com/t/firstmatecommunity/shared_invite/zt-2u9f0yw5e-Av1~asi1XbJQvZ2d4mhyMg">
                            <Card className="link-card">Join our Slack community<ExpandAltOutlined
                                style={iconLinkStyle}/></Card>
                        </a>
                    </Col>
                    <Col span={12}>
                        <a target="_blank" rel="noopener noreferrer" href="https://github.com/firstmatecloud/demo-microservice">
                            <Card className="link-card">Look at our demo repository.<ExpandAltOutlined
                                style={iconLinkStyle}/></Card>
                        </a>
                    </Col>
                </Row>
            </div>
        </div>
    );


}
