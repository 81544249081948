import {squircle, zoomies} from 'ldrs'
import * as React from "react";

squircle.register()
zoomies.register()

export default function Loader({size, text, type = "1"}) {
    if (type === "1")
        return (
            <div style={{textAlign: "center"}}>
                <p style={{marginBottom: 20}}>{text}</p>
                <l-squircle
                    size={size}
                    stroke="10"
                    stroke-length="0.25"
                    bg-opacity="0.2"
                    speed="2"
                    color="white"
                ></l-squircle>
            </div>
        )
    if (type === "2")
        return (
            <div style={{textAlign: "center"}}>
                <p style={{marginBottom: 20}}>{text}</p>
                <l-zoomies
                    size={size}
                    stroke="10"
                    stroke-length="0.25"
                    bg-opacity="0.2"
                    speed="2"
                    color="white"
                ></l-zoomies>
            </div>
        )
}