import {Button, Form, Input, Modal} from 'antd';
import {CodeOutlined} from "@ant-design/icons";

export default function AzureDevModal({open, onClose}) {

    const addAccessRight = async (values) => {
        window.location.replace(`https://login.microsoftonline.com/${values.tenantId}/adminconsent?client_id=${window._env_.AZUREDEV_APP_ID}`);
    }


    return (
        <Modal centered open={open} className={"with-background"}
               onCancel={onClose} width={"1200px"} footer={[]}>

            <div className="modal-background"></div>
            <div className="modal-switch">
            </div>
            <div className="modal-icon"><CodeOutlined /></div>
            <h2 style={{marginRight: 20, marginTop: -5}}>Add Azure Devops </h2>
            <div style={{marginTop: 20}}><p>Please provide us your tenant id so we can redirect you to the app installation.</p></div>
            <>
                <Form
                    name="azure-setup"
                    layout="vertical"
                    onFinish={addAccessRight}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 800, marginTop: 20}}
                    autoComplete="off"
                    initialValues={{
                        orgName: "",
                        tenantId: ""
                    }}
                >
                    <Form.Item
                        label="The tenant Id of your azure devops account."
                        name="tenantId"
                        rules={[{required: true, message: 'Please double check your tenantId'}]}>
                        <Input placeholder="00000000-0000-0000-0000-000000000000" style={{width: 300}}/>
                    </Form.Item>
                </Form>
                <Button type="primary" form="azure-setup" key="submit" htmlType="submit">
                    Next Step
                </Button>
            </>

        </Modal>)

}



