import api from "./api";


export default class BackendService {

    //USER
    async getMe() {
        const resp = await api.get('/users/me')
        return resp.data
    }
    async getUsers() {
        const resp = await api.get('/users')
        return resp.data
    }

    async inviteUser(user) {
        const resp = await api.post('/users/invite', user)
        return resp.data
    }
    async acceptInvite(token) {
        const resp = await api.post('/users/invite/accept', token)
        return resp.data
    }

    async initOrganisation(organisation) {
        const resp = await api.post('/organisations/init', organisation)
        return resp.data
    }
    async getOnboardingStatus() {
        const resp = await api.get('/organisations/me/onboarding')
        return resp.data
    }

    async repoRefresh() {
        const resp = await api.post('/organisations/me/repo-refresh')
        return resp.data
    }


    async getClusters() {
        const resp = await api.get('/clusters')
        return resp.data
    }

    async getClusterStatus(id) {
        const resp = await api.get('/clusters/' + id + '/connected')
        return resp.data
    }

    async addCluster(cluster) {
        const resp = await api.post('/clusters', cluster)
        return resp.data
    }

    async getGeneralMetrics(queryParams) {
        const resp = await api.get('/metrics/general', {
            params: queryParams
        })
        return resp.data
    }
    async getProjectMetrics(queryParams) {
        const resp = await api.get('/metrics/projects', {
            params: queryParams
        })
        return resp.data
    }

    async getFeatures() {
        const resp = await api.get('/features')
        return resp.data
    }

    async getTickets() {
        const resp = await api.get('/tickets')
        return resp.data
    }

    async getSuggestions() {
        const resp = await api.get('/suggestions')
        return resp.data
    }
    async getAccessRights() {
        const resp = await api.get('/organisations/me/access')
        return resp.data
    }
    async addAccessRights(type, accessRight) {
        const resp = await api.post(`/organisations/me/access/${type}`, accessRight)
        return resp.data
    }
    async getSubscriptionDetails() {
        const resp = await api.get(`/subscription`)
        return resp.data
    }
    async changeSeat(record) {
        const resp = await api.post(`/subscription/change-seat`, record)
        return resp.data
    }
    async changeSubscriptionSettings(settings) {
        const resp = await api.post(`/subscription/settings`, settings)
        return resp.data
    }
    async getPRStats(){
        const resp = await api.get(`/stats/prs`)
        return resp.data

    }
}