import {createContext, useEffect, useState, useCallback, useContext} from 'react';
import {UserContext} from "./UserProvider";

export const SuggestionContex = createContext([]);


export default function SuggestionProvider({children}) {
    const [suggestions, setSuggestions] = useState([])
    const {backendService} = useContext(UserContext);


    const fetchData = useCallback(async () => {
        const suggestions = await backendService.getSuggestions()
        setSuggestions(suggestions);
    }, [backendService]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <SuggestionContex.Provider
            value={{suggestions}}>
            {children}
        </SuggestionContex.Provider>
    )
}